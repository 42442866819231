import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const breakPoints = {
  Xsmall: "360px",
  small: "480px",
  tablet: "767px",
  medium: "1023px",
  large: "1105px",
  Xlarge: "1199px",
  XXlarge: "1285px",
  massive: "1520px",
};
const devices = {
  mobile: `(max-width: ${breakPoints.tablet})`,
  tablet: `(max-width: ${breakPoints.medium})`,
  largeTablet: `(max-width: ${breakPoints.large})`,
  smallDesktop: `(max-width: ${breakPoints.Xlarge})`,
  midDesktop: `(max-width: ${breakPoints.XXlarge})`,
  massive: `(min-width: ${breakPoints.massive})`,
};


declare module "@mui/material/styles" {
  interface CustomTheme {
    devices: {
      mobile: string;
      tablet: string;
      largeTablet: string;
      smallDesktop: string;
      midDesktop: string;
      massive: string;
    };
    sizes: {
      maxWidth: {
        mobile: string;
        tablet: string;
        largeTablet: string;
        smallDesktop: string;
        midDesktop: string;
        desktop: string;
        massive: string;
      }
    },
    colors: any
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
  // allow configuration using `createTheme`
  interface ThemeOptions {
    devices: {
      mobile: string;
      tablet: string;
      largeTablet: string;
      smallDesktop: string;
      midDesktop: string;
      massive: string;
    };
  }
}

// Create a theme instance.
const theme = createTheme({
  devices,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1080,
      xl: 1536,
    },
  },
  sizes: {
    maxWidth: {
      mobile: '335px',
      tablet: '704px',
      largeTablet: '944px',
      smallDesktop: '1024px',
      midDesktop: '1100px',
      desktop: '1200px',
      massive: '1440px',
    },
  },
  colors: {
    black: 'var(--redsift-color-neutral-black)',
    darkGrey: 'var(--redsift-color-neutral-darkgrey)',
    midGrey: 'var(--redsift-color-neutral-midgrey)',
    lightGrey: 'var(--redsift-color-neutral-lightgrey)',
    xLightGrey: 'var(--redsift-color-neutral-xlightgrey)',
    lighterGrey: 'var(--redsift-color-neutral-lightgrey)',
    white: 'var(--redsift-color-neutral-white)',
    green: 'var(--redsift-color-success-primary)',
    teal: 'var(--redsift-color-ctas-secondary-primary)',
    red: 'var(--redsift-color-error-primary)',
    lightRed: 'var(--redsift-color-error-hover)',
    secondaryRed: 'var(--redsift-color-error-secondary)',
    orange: 'var(--redsift-color-warning-primary)',
    darkOrange: 'var(--redsift-color-warning-secondary)',
    onDmarcGreen: 'var(--redsift-color-ondmarc-primary)',
    onInboxGreen: 'var(--redsift-color-oninbox-primary)',
    onDomainGreen: 'var(--redsift-color-ondomain-primary)',
    blue: 'var(--redsift-color-website-primary)',
    darkBlue: 'var(--redsift-color-website-secondary)',
    ctaBlue: 'var(--redsift-color-ctas-primary-primary)',
    hardenize: 'var(--redsift-color-hardenize-primary)',
    primary: {
      hover: 'var(--redsift-color-website-hover)',
    },
    yellow: 'var(----redsift-color-presentation-yellow-default)',
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#0079E1",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    fontFamily: ["Raleway", "Arial", "sans-serif"].join(","),
  },
});

export const colors: Record<string, string> = {
  red: "var(--redsift-color-error-primary)",
  blue: "var(--redsift-color-website-primary)",
  black: "var(--redsift-color-neutral-black)",
  white: "var(--redsift-color-neutral-white)",
  onDmarcGreen: "var(--redsift-color-ondmarc-primary)",
  onInboxGreen: "var(--redsift-color-oninbox-primary)",
  onDomainGreen: "var(--redsift-color-ondomain-primary)",
  hardenize: "var(--redsift-color-hardenize-primary)",
};

export default theme;
