import type { AlertColor } from "@mui/material";
import log from "loglevel";
import React from "react";
import { RadarSizeMode } from "../libs/constants";

export interface TAppContext {
  setAlert: (message: string, severity: AlertColor, duration?: number) => void;
  setLoading: (loading: boolean) => void;
  rightDrawerOpen: boolean;
  setRightDrawerOpen: Function;
  radarParams: {
    radarOpen: boolean;
    showRadar: boolean;
    input?: string;
    prompt?: string;
    product?: string;
  };
  setRadarParams: Function;
  radarSize: { sizeMode: RadarSizeMode };
  setRadarSize: Function;
}

// Create a Context
const AppContext = React.createContext<TAppContext>({
  setAlert: (message: string, severity: AlertColor, duration?: number) =>
    log.error(
      "AppContext::setAlert: not implemented:",
      message,
      severity,
      duration
    ),
  setLoading: (loading: boolean) =>
    log.error("AppContext::setLoading: not implemented", loading),
  rightDrawerOpen: false,
  setRightDrawerOpen: () => {},
  radarParams: { radarOpen: false, showRadar: false },
  setRadarParams: () => {},
  radarSize: { sizeMode: "PANEL" },
  setRadarSize: () => {},
});

export default AppContext;
