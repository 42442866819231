const RedSiftLoadingIndicator = ({ width = 100, show = true }) => {
  return (
    <div
      style={{
        display: show ? "flex" : "none",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <object
        width={width}
        type="image/svg+xml"
        data="/files/red-sift-loading-indicator.svg"
      >
        loading-indicator
      </object>
    </div>
  );
};

export default RedSiftLoadingIndicator;
